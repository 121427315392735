
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {TABLE_FOOTER_OPTIONS} from "@/misc/TableFooterOptions";
import Advertisement from "../../models/Advertisement.model";
import {AdvertisementSizes} from "@/misc/AdvertisementSizes";
import {AdvertisementSize} from "@/enum/AdvertisementSize.enum";

@Component
export default class AdvertisementsListComponent extends Vue {
  @Prop({required: true})
  private advertisements!: Advertisement[];

  @Prop({required: true})
  private isLoading!: boolean;

  @Prop({required: true})
  private search!: string;

  @Prop({required: true})
  private onChangeAdvertisementActive!: (advertisement: Advertisement, active: boolean) => void;

  @Prop({required: true})
  private editAdvertisement!: (advertisement: Advertisement) => void;

  @Prop({required: true})
  private deleteAdvertisement!: (advertisement: Advertisement) => void;

  private onActiveStateChange(ad: Advertisement, active: boolean) {
    this.onChangeAdvertisementActive(ad, active);
  }

  /**
   * headers of the table
   */
  private headers = [
    { text: this.$t('GENERAL.IMAGE'), align: 'start', sortable: false, value: 'image' },
    { text: this.$t('ADVERTISEMENTS.TABLE.CUSTOMER'), align: 'start', sortable: true, value: 'id' },
    { text: this.$t('ADVERTISEMENTS.TABLE.DATE'), align: 'start', sortable: true, value: 'url' },
    { text: this.$t('ADVERTISEMENTS.TABLE.FORMAT'), align: 'start', sortable: true, value: 'url' },
    { text: this.$t('ADVERTISEMENTS.TABLE.ACTIVE'), align: 'start', sortable: true, value: 'url' },
    { text: '', align: 'start', sortable: false, value: 'active' },
    { text: this.$t('GENERAL.EDIT'), align: 'start', sortable: true, value: 'actions' }
  ];

  private getImageFormat(size: AdvertisementSize) {
    const item = AdvertisementSizes.find((a) => a.value === size);
    if(!item) return this.$t('ADVERTISEMENTS.FORMAT_NOT_FOUND');
    return item.label;
  }

  private getTimePeriod(item: Advertisement) {
    if(!item.activeFrom || !item.activeTo) return '-';
    const from = this.$formatDateFromString(item.activeFrom!);
    const to = this.$formatDateFromString(item.activeTo!);
    return from + '-' + to;
  }

  private customerName(item: Advertisement) {
     return item.customerName ?? '-';
  }

  private activeState(item: Advertisement) {
    return item.active ? this.$t('ADVERTISEMENTS.ACTIVE'): this.$t('ADVERTISEMENTS.INACTIVE');
  }

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;
}
